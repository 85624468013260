<template>
    <div class="bio_link_main_view">

        <div class="view_inner d-flex flex-column">

            <div class="view_top d-flex align-items-center">
                <h4 class="title">Select Type</h4>
                <div class="view-right d-flex align-items-center ml-auto">
                    <bio-shortened-url></bio-shortened-url>
                    <router-link class="cross_icon ml-auto" :to="{name: 'bio_links'}">
                        <i class="fal fa-times"></i>
                    </router-link>
                </div>
            </div>

            <div class="view_center">
                <div class="steps_dot mb-3">
                    <router-link :to="{name: 'select_content'}"
                                 class="d-inline-block btn---cta light-blue btn-round">
                        <span>Go Back</span>
                    </router-link>
                    <div class="circles">
                        <span class="dotted_circle active"></span>
                        <span class="dotted_circle active"></span>
                        <span class="dotted_circle active"></span>
                        <span class="dotted_circle"></span>
                        <span class="dotted_circle"></span>
                    </div>
                </div>
                <div class="block-area">
                    <div class="block-heading text-center">
                        <h2 class="">Most Used Blocks</h2>
                    </div>

                    <div class="block-list text-center">
                        <div class="block-item " @click="navigateContentSocialBlock()" data-cy="social-icons">
                            <i class="fal fa-ellipsis-h fw-500 d-flex align-items-center justify-content-center"></i>
                            <p>Social Icons</p>
                        </div>

                        <div @click="navigateContentLinksBlock()" class="block-item" data-cy="links-block">
                            <i class="fal fa-bars d-flex align-items-center justify-content-center"></i>
                            <p>Links</p>
                        </div>

                        <div @click="navigateContentRSSBlock()" class="block-item" data-cy="rss-block">
                            <i class="fal fa-rss d-flex align-items-center justify-content-center"></i>
                            <p>RSS Feed</p>
                        </div>
                    </div>

<!--                    <div class="btn-block  mt-3">-->
<!--                        <router-link :to="{name: 'select_content'}"-->
<!--                                     class="d-inline-block btn-&#45;&#45;cta light-blue btn-round">-->
<!--                            <span>Go Back</span>-->
<!--                        </router-link>-->

<!--                    </div>-->
                </div>

            </div>

        </div>
    </div>

</template>
<script>
import { bioLinkTypes } from '@/state/modules/mutation-types'
import BioShortenedURL from './BioShortenedURL'
import { mapGetters } from 'vuex'

export default (
  {
    components: {
      'bio-shortened-url': BioShortenedURL
    },
    computed: {
      ...mapGetters(['getBioLinkAdd'])
    },
    methods: {
      navigateContentLinksBlock () {
        this.$store.commit(bioLinkTypes.SET_BIO_CONTENT_SECTION_TYPE, 'links')
        this.$router.push({ name: 'content_links_block' })
      },
      navigateContentSocialBlock () {
        this.$store.commit(bioLinkTypes.SET_BIO_CONTENT_SECTION_TYPE, 'social')
        this.$router.push({ name: 'content_social_channels' })
      },
      navigateContentRSSBlock () {
        this.$store.commit(bioLinkTypes.SET_BIO_CONTENT_SECTION_TYPE, 'rss')
        this.$router.push({ name: 'content_rss_block' })
      }
    },
    created () {
      // if (!this.getBioLinkAdd.brand_id) this.$router.push({ name: 'select_content' })
    }
  }
)
</script>

<style scoped>
    .bio_link_layout .bio_link_main_view .view_inner .view_center .steps_dot .circles {
        width: 620px;
        text-align: center;
        display: inline-block;
    }
</style>
